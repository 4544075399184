import { defineStyleConfig } from '@chakra-ui/react';

/**
 * Style configuration for RadioButton component
 */
export const radioButtonStyleConfig = defineStyleConfig({
    baseStyle: {
        borderRadius: 'md',
        cursor: 'pointer',
        _checked: {
            bg: 'white',
            color: 'black',
            borderColor: 'white',
        },
        _hover: {
            bg: 'gray.50',
        },
    },
    sizes: {
        sm: {
            fontSize: 'sm',
            px: '16px',
            py: '2px',
            borderRadius: 'var(--chakra-radii-sm)',
        },
        md: {
            fontSize: 'md',
            px: '24px',
            py: '4px',
            borderRadius: 'var(--chakra-radii-md)',
        },
        lg: {
            fontSize: 'lg',
            px: '32px',
            py: '6px',
            borderRadius: 'var(--chakra-radii-lg)',
        },
    },
    variants: {
        connected: {
            bg: 'white',
            border: '1px',
            borderLeft: 0,
            borderRight: 0,
            borderColor: 'gray.300',

            margin: '0',
            padding: '6px 18px',
            borderRadius: 0,
            _checked: {
                bg: 'brand.500',
                color: 'white',
                borderColor: 'brand.500',
            },
            _hover: {
                bg: 'brand.600',
                color: 'white',
                borderColor: 'brand.600',
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
});

/**
 * Style configuration for RadioButtonGroup component
 */
export const radioButtonGroupStyleConfig = defineStyleConfig({
    baseStyle: {
        alignItems: 'center',
        bg: 'gray.100',
        justifyContent: 'space-between',
        border: '2px',
        borderColor: 'gray.100',
        direction: 'row',
        flex: '1',
        display: 'flex',
    },
    sizes: {
        sm: {
            gap: '6px',
            padding: '1px 8px',
        },
        md: {
            gap: '8px',
            padding: '2px 16px',
        },
        lg: {
            gap: '12px',
            padding: '4px 24px',
        },
    },
    variants: {
        connected: {
            border: '1px',
            borderColor: 'gray.300',
            borderTop: 0,
            borderBottom: 0,
            gap: '0',
            margin: '0',
            bg: 'white',
            padding: '0px',
        },
    },
    defaultProps: {
        size: 'md',
    },
});
