import * as React from 'react';

export type BreadcrumbItem = {
    label: string;
    href: string;
};

type BreadcrumbContextType = {
    breadcrumbs: BreadcrumbItem[];
    setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
};

const BreadcrumbContext = React.createContext<BreadcrumbContextType>({
    breadcrumbs: [],
    setBreadcrumbs: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const BreadcrumbProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>([]);

    return <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadcrumb = () => {
    const context = React.useContext(BreadcrumbContext);

    if (!context) {
        throw new Error('useBreadcrumb must be used within BreadcrumbProvider');
    }

    return context;
};
