import { theme } from '@chakra-ui/pro-theme';
import { CardProps, extendTheme } from '@chakra-ui/react';
import { brandSelectorStyleConfig } from '@common/brand/styleConfig';
import { categorySelectorStyleConfig } from '@common/category/styleConfig';
import { radioButtonGroupStyleConfig, radioButtonStyleConfig } from '@common/RadioButton/styleConfig';
import { selectorStyleConfig } from '@common/selector/styleConfig';
import { timeRangePickerStyleConfig } from '@common/timerange/styleConfig';
import '@fontsource-variable/inter';
import { iconAvatarStyleConfig } from '@root/app/_common/icons/styleConfig';

const brandOceanPalette = {
    50: '#D4F5FF',
    100: '#AADFEE',
    200: '#80C8DD',
    300: '#55B2CC',
    400: '#2B9BBB',
    500: '#0185AA',
    600: '#016A88',
    700: '#015066',
    800: '#003544',
    900: '#001B22',
};

export const alertIcons = {
    success: 'FaRegCheckCircle',
    error: 'FiAlertOctagon',
    warning: 'GrStatusWarning',
    info: 'FiInfo',
};

export const alertColors = {
    success: 'green.500',
    error: 'red.500',
    warning: 'orange.500',
    info: 'blue.500',
};

const proTheme = extendTheme(theme);

const extensions = {
    config: {
        // makes it clear in the browser which variables are coming from chakra
        cssVarPrefix: 'chakra',
    },
    styles: {
        global: {
            ':root': {
                // nav
                '--nav-height': '67px',
                // sidebar
                '--sidebar-width': '264px',
                '--sidebar-width-not-expanded': '16px',
                // CtTabs
                '--tabs-list-height': '42px',
                '--tabs-panel-pl': '2rem',
                '--tabs-panel-pr': '2rem',
                '--tabs-panel-pt': '1.25rem',
                '--tabs-panel-pb': '1.25rem',
                // InfoCard
                '--info-card-height': '160px',
                // Table
                '--table-footer-height': '64px',
                // Modal
                '--modal-body-pt': '0.5rem',
                '--modal-body-pb': '0.5rem',
                '--modal-footer-height': '64px',
            },
        },
    },
    colors: {
        ...proTheme.colors,
        ocean: brandOceanPalette,
        ebonyClay: {
            50: '#BBC3D5',
            100: '#9EA5B7',
            200: '#808899',
            300: '#636A7C',
            400: '#454D5E',
            500: '#282F40',
            600: '#202633',
            700: '#181C26',
            800: '#10131A',
            900: '#08090D',
        },
        brand: brandOceanPalette,
    },
    fonts: {
        heading: 'var(--font-roboto), -apple-system, system-ui, sans-serif',
        body: 'var(--font-roboto), -apple-system, system-ui, sans-serif',
    },
    components: {
        Input: {
            baseStyle: {
                field: {
                    _disabled: {
                        bg: 'var(--chakra-colors-gray-200)',
                    },
                },
            },
        },
        RadioButton: radioButtonStyleConfig,
        RadioButtonGroup: radioButtonGroupStyleConfig,
        TimeRangePicker: timeRangePickerStyleConfig,
        Selector: selectorStyleConfig,
        CategorySelector: categorySelectorStyleConfig,
        BrandSelector: brandSelectorStyleConfig,
        IconAvatar: iconAvatarStyleConfig,
        Alert: {
            baseStyle: (props: { status: keyof typeof alertIcons }) => {
                const icon = alertIcons[props.status];
                const color = alertColors[props.status];
                const backgroundColor = `${props.status}.100`;

                return {
                    container: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        borderRadius: '0.25rem',
                        borderLeftWidth: '6px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: color,
                        backgroundColor,
                    },
                    icon: {
                        minWidth: '20px',
                        minHeight: '20px',
                        as: icon,
                        color,
                    },
                    description: {
                        color: 'gray.700',
                        flex: 1,
                    },
                };
            },
            defaultProps: {
                status: 'info',
            },
        },
        Card: {
            baseStyle: {
                ...proTheme.components.Card.baseStyle,
                header: {
                    ...proTheme.components.Card.baseStyle.header,
                    pb: 0,
                },
            },
            variants: {
                error: (props: CardProps) => ({
                    ...proTheme.components.Card.variants.outline,
                    ...{
                        container: {
                            ...proTheme.components.Card.variants.outline.container,
                            borderStyle: 'solid',
                            borderColor: 'red.500',
                        },
                    },
                }),
            },
        },
    },
};

export const connectTheme = extendTheme(proTheme, extensions);
