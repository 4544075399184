'use client';

import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache,
    NormalizedCacheObject,
    fromPromise,
} from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { connectTheme } from '@app/theme';
import { ChakraProvider } from '@chakra-ui/react';
import { BreadcrumbProvider } from '@common/context/breadcrumb-context';
import { SDKProvider } from '@connect-core/connect-sdk';
import config from '@root/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import axios from 'axios';
import { Session } from 'next-auth';
import { SessionProvider, getSession } from 'next-auth/react';
import NextTopLoader from 'nextjs-toploader';
import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { ConnectEnv } from './_lib/env';

const getAuthToken = async () => {
    return await getSession().then((session: Session | null) => session?.user?.token);
};
const removeTypenameLink = removeTypenameFromVariables();

const authLink = new ApolloLink((operation, forward) =>
    fromPromise(
        getAuthToken().then((rawToken: string | null) => {
            const token = rawToken ? `Bearer ${rawToken}` : '';
            operation.setContext({
                headers: {
                    ...operation.getContext().headers,
                    authorization: token,
                },
            });
        })
    ).flatMap(() => forward(operation))
);

const uploadLinkFactory = (url: string) =>
    createUploadLink({
        uri: `${url}/graphql`,
        headers: { 'Apollo-Require-Preflight': 'true' },
    });

const graphqlClientFactory: (url: string) => ApolloClient<NormalizedCacheObject> = (url: string) =>
    new ApolloClient({
        link: ApolloLink.from([removeTypenameLink, authLink, uploadLinkFactory(url)]),
        cache: new InMemoryCache({}),
    });

// Create an Axios instance with base configuration
const axiosClientFactory = (url: string) => {
    const client = axios.create({
        baseURL: `${url}/api`, // Replace with your API base URL
        headers: {
            'Content-Type': 'application/json',
        },
    });

    client.interceptors.request.use(
        async (config) => {
            const token = await getAuthToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`; // Adjust if your auth scheme is different
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return client;
};
// Use request interceptors to add the auth token to each request

// All the client wrappers are here (they can't be in server components)
// need to update these comments
// 1. SessionProvider: Allow the useSession from next-auth (find out if user is auth or not)
// 2. ApolloProvider: Allow the use of hooks from apollo-client
// 3. NextTopLoader: Show a progress bar at the top when navigating between pages
// 4. Toaster: Show Success/Error messages anywhere from the app with toast()
// 5. Tooltip: Show tooltips if any JSX elements has these 2 attributes: data-tooltip-id="tooltip" data-tooltip-content=""
// 6. CrispChat: Set Crisp customer chat support (see above)
export const Providers = ({
    children,
    env,
    loadConfig,
}: React.PropsWithChildren & { env: ConnectEnv; loadConfig: () => Promise<ConnectEnv> }) => {
    const queryClient = new QueryClient();
    const [environment, setEnvironment] = React.useState<ConnectEnv>(env);
    const gqlClient = graphqlClientFactory(environment.api.url);

    React.useEffect(() => {
        loadConfig().then((env) => {
            setEnvironment(env);
        });
    }, [loadConfig, env, setEnvironment]);

    return (
        <>
            <SessionProvider>
                <ApolloProvider client={gqlClient}>
                    <QueryClientProvider client={queryClient}>
                        <SDKProvider apolloClient={gqlClient} axiosClient={axiosClientFactory(environment.api.url)}>
                            <BreadcrumbProvider>
                                <ChakraProvider theme={connectTheme}>
                                    {/* Show a progress bar at the top when navigating between pages */}
                                    <NextTopLoader color={config.colors.main} showSpinner={false} />
                                    {/* Content inside app/page.js files  */}
                                    {children}
                                    {/* Show Success/Error messages anywhere from the app with toast() */}
                                    <Toaster
                                        toastOptions={{
                                            duration: 3000,
                                        }}
                                    />
                                    {/* Show tooltips if any JSX elements has these 2 attributes: data-tooltip-id="tooltip" data-tooltip-content="" */}
                                    <Tooltip id="tooltip" className="z-[60] !opacity-100 max-w-sm shadow-lg" />
                                </ChakraProvider>
                            </BreadcrumbProvider>
                        </SDKProvider>
                    </QueryClientProvider>
                </ApolloProvider>
            </SessionProvider>
        </>
    );
};
