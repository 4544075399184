import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['circle', 'icon']);

export const iconAvatarStyleConfig = helpers.defineMultiStyleConfig({
    baseStyle: {
        circle: {
            borderRadius: '50%',
            verticalAlign: 'middle',
            textAlign: 'center',
        },
    },
    sizes: {
        xs: {
            circle: {
                w: '20px',
                h: '20px',
                lineHeight: '20px',
            },
            icon: {
                w: '15px',
                h: '15px',
            },
        },
        sm: {
            circle: {
                w: '35px',
                h: '35px',
                lineHeight: '35px',
            },
            icon: {
                w: '23px',
                h: '23px',
            },
        },
        md: {
            circle: {
                w: '58px',
                h: '58px',
                lineHeight: '58px',
            },
            icon: {
                w: '32px',
                h: '32px',
            },
        },
        lg: {
            circle: {
                w: '87px',
                h: '87px',
                lineHeight: '87px',
            },
            icon: {
                w: '50px',
                h: '50px',
            },
        },
    },

    defaultProps: {
        size: 'md',
    },
});
