import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['closeButton', 'dropdownButton', 'input', 'menu', 'rangeHeader']);

export const timeRangePickerStyleConfig = helpers.defineMultiStyleConfig({
    baseStyle: {
        dropdownButton: {
            borderLeft: '1px solid var(--chakra-colors-border-default)',
            m: '0',
            _hover: {
                background: 'var(--chakra-colors-gray-100)',
            },
        },
        closeButton: {
            color: 'var(--chakra-colors-gray-600)',
        },
        input: {
            bg: 'var(--chakra-colors-white)',
            border: '1px solid var(--chakra-colors-border-default)',
            cursor: 'pointer',
            p: 2,
            pr: 0,
            direction: 'row',
            flex: '1',
            display: 'flex',
            alignItems: 'center',
        },
        menu: {
            zIndex: 9999,
            mt: '2px',
            position: 'absolute',
            bg: 'var(--chakra-colors-white)',
            border: '1px solid var(--chakra-colors-border-default)',
        },
    },
    sizes: {
        sm: {
            closeButton: {
                fontSize: '8px',
                padding: '12px 0',
            },
            dropdownButton: {
                borderRightRadius: 'var(--chakra-radii-md)',
                height: 'var(--chakra-sizes-9)',
                px: '16px',
            },
            input: {
                borderRadius: 'var(--chakra-radii-lg)',
                fontSize: 'sm',
                h: 'var(--chakra-sizes-9)',
                gap: '6px',
            },
            menu: {
                py: '6px',
                px: '12px',
                borderRadius: 'var(--chakra-radii-sm)',
                boxShadow: 'var(--chakra-shadows-sm)',
            },
        },
        md: {
            closeButton: {
                fontSize: 'xs',
                padding: '16px 0',
            },
            dropdownButton: {
                borderRightRadius: 'var(--chakra-radii-md)',
                h: 'var(--chakra-sizes-10)',
                px: '16px',
            },
            input: {
                borderRadius: 'var(--chakra-radii-lg)',
                fontSize: 'md',
                h: 'var(--chakra-sizes-10)',
                gap: '8px',
            },
            menu: {
                py: '8px',
                px: '16px',
                borderRadius: 'var(--chakra-radii-md)',
                boxShadow: 'var(--chakra-shadows-md)',
            },
        },
        lg: {
            closeButton: {
                fontSize: 'md',
                padding: '8px 0',
            },
            dropdownButton: {
                borderRightRadius: 'var(--chakra-radii-lg)',
                height: 'var(--chakra-sizes-11)',
                px: '16px',
            },
            input: {
                borderRadius: 'var(--chakra-radii-lg)',
                fontSize: 'lg',
                h: 'var(--chakra-sizes-11)',
                gap: '10px',
            },
            menu: {
                py: '12px',
                px: '20px',
                borderRadius: 'var(--chakra-radii-lg)',
                boxShadow: 'var(--chakra-shadows-lg)',
            },
        },
    },

    defaultProps: {
        size: 'md',
    },
});
