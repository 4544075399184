import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
    'closeButton',
    'dropdownButton',
    'disabled',
    'input',
    'menu',
    'menuBody',
    'menuLeft',
    'menuSearch',
    'menuPrimaryContent',
    'menuRight',
    'menuFooter',
]);

export const selectorStyleConfig = helpers.defineMultiStyleConfig({
    baseStyle: {
        disabled: {
            bg: 'var(--chakra-colors-gray-100)',
            cursor: 'not-allowed',
        },
        dropdownButton: {
            borderLeft: '1px solid var(--chakra-colors-border-default)',
            m: '0',
            _hover: {
                background: 'var(--chakra-colors-gray-100)',
            },
        },
        closeButton: {
            color: 'var(--chakra-colors-gray-600)',
        },
        input: {
            bg: 'var(--chakra-colors-white)',
            border: '1px solid var(--chakra-colors-border-default)',
            cursor: 'pointer',
            p: 2,
            pr: 0,
            direction: 'row',
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            minWidth: '265px',
        },
        menu: {
            zIndex: 'var(--chakra-zIndices-dropdown)',
            position: 'absolute',
            bg: 'var(--chakra-colors-white)',
            border: '1px solid var(--chakra-colors-border-default)',
            minHeight: '300px',
        },
        menuBody: {
            bg: 'var(--chakra-colors-white)',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        menuSearch: {
            display: 'flex',
            width: '352px',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px 0 16px',
        },
        menuPrimaryContent: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            my: '8px',
            width: '352px',
        },
        menuRight: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            minWidth: '462px',
            borderLeft: '1px solid var(--chakra-colors-gray-300)',
        },
        menuFooter: {
            marginTop: 'auto',
            backgroundColor: 'var(--chakra-colors-white)',
            borderTop: '1px solid var(--chakra-colors-gray-300)',
            width: '100%',
        },
    },
    sizes: {
        sm: {
            closeButton: {
                fontSize: '8px',
                padding: '12px 0',
            },
            dropdownButton: {
                borderRightRadius: 'var(--chakra-radii-md)',
                height: 'var(--chakra-sizes-9)',
                px: '16px',
            },
            input: {
                borderRadius: 'var(--chakra-radii-lg)',
                fontSize: 'sm',
                h: 'var(--chakra-sizes-9)',
                gap: '6px',
            },
            menu: {
                borderRadius: 'var(--chakra-radii-sm)',
                boxShadow: 'var(--chakra-shadows-sm)',
            },
            menuSearch: {
                gap: '6px',
            },
            menuPrimaryContent: {
                gap: '6px',
                height: '180px',
            },
            menuRight: {
                height: '250px',
            },
            menuFooter: {
                borderBottomRadius: 'var(--chakra-radii-sm)',
                pt: '6px',
                px: '12px',
            },
        },
        md: {
            closeButton: {
                fontSize: 'xs',
                padding: '16px 0',
            },
            dropdownButton: {
                borderRightRadius: 'var(--chakra-radii-md)',
                h: 'var(--chakra-sizes-10)',
                px: '16px',
            },
            input: {
                borderRadius: 'var(--chakra-radii-lg)',
                fontSize: 'md',
                h: 'var(--chakra-sizes-10)',
                gap: '8px',
            },
            menu: {
                borderRadius: 'var(--chakra-radii-md)',
                boxShadow: 'var(--chakra-shadows-md)',
            },
            menuSearch: {
                gap: '8px',
            },
            menuPrimaryContent: {
                gap: '8px',
                height: '180px',
            },
            menuRight: {
                height: '250px',
            },
            menuFooter: {
                borderBottomRadius: 'var(--chakra-radii-md)',
                py: '8px',
                px: '16px',
            },
        },
        lg: {
            closeButton: {
                fontSize: 'md',
                padding: '8px 0',
            },
            dropdownButton: {
                borderRightRadius: 'var(--chakra-radii-lg)',
                height: 'var(--chakra-sizes-11)',
                px: '16px',
            },
            input: {
                borderRadius: 'var(--chakra-radii-lg)',
                fontSize: 'lg',
                h: 'var(--chakra-sizes-11)',
                gap: '10px',
            },
            menu: {
                borderRadius: 'var(--chakra-radii-lg)',
                boxShadow: 'var(--chakra-shadows-lg)',
            },
            menuSearch: {
                gap: '16px',
            },
            menuPrimaryContent: {
                gap: '16px',
                height: '250px',
            },
            menuRight: {
                height: '250px',
            },
            menuFooter: {
                borderBottomRadius: 'var(--chakra-radii-lg)',
                py: '12px',
                px: '20px',
            },
        },
    },

    defaultProps: {
        size: 'md',
    },
});
